import { Label } from '@radix-ui/react-label';
import React from 'react';

import { TermDataProps } from './term';
import Box from '../ui/box';
import Checkbox from '../ui/checkbox';

export default function TermContent({
   termData,
   agreeCheck,
   index,
   height,
   handleCheckboxChange,
}: {
   termData: TermDataProps;
   index: number;
   agreeCheck: boolean[];
   height?: string;
   handleCheckboxChange: (index: number) => void;
}) {
   return (
      <Box key={termData.id} className={`h-[${height ? height : '120px'}]`}>
         <Checkbox
            id={termData.id}
            checked={agreeCheck[index]}
            onCheckedChange={() => handleCheckboxChange(index)}
         >
            <Label htmlFor={termData.id} className='text-xs whitespace-nowrap '>
               [필수]
            </Label>
            <Label htmlFor={termData.id} className='-ml-1 text-xs text-gray02'>
               {termData.subText}
            </Label>
         </Checkbox>
         <div
            className={`${height ?? 'h-[120px]'} overflow-auto mt-1 py-1 text-[0.8rem] flex flex-col gap-2`}
         >
            {termData.desc}
         </div>
      </Box>
   );
}
