import { Gnb, GnbGoBack, GnbTitle } from '@components/common/gnb';
import { GnhTitle } from '@components/common/gnh';
import { ContentSection, HeaderSection } from '@components/layouts';
import SinglePageLayout from '@components/layouts/SinglePageLayout';
import Box from '@components/ui/box';
import Selector from '@components/ui/selector';
import { HEADING_TEXT, COUNCIL_LIST } from '@constants/heading';
import React from 'react';

export default function Greeting() {
   return (
      <React.Fragment>
         <Gnb>
            <GnbGoBack />
            <GnbTitle>{HEADING_TEXT.COUNCIL.HEAD}</GnbTitle>
         </Gnb>
         <HeaderSection className='pt-[38px] ml-[29px] pb-[30px]'>
            <GnhTitle>{HEADING_TEXT.COUNCIL.HEAD}</GnhTitle>
            <Selector list={COUNCIL_LIST} subHeadingText={HEADING_TEXT.GREETING.SUBHEAD} />
         </HeaderSection>
         <ContentSection showNav={true} className='mb-10'>
            <SinglePageLayout>
               <Box className='text-sm leading-5 flex flex-col gap-5' type='shadow'>
                  <section className='flex flex-col gap-5'>
                     <p>
                        안녕하십니까 단국대학교 죽전 캠퍼스 학우 여러분, <br />
                        <strong>2024년 제56대 DO DREAM 총학생회</strong>입니다. <br />
                     </p>
                     <p>
                        2024년 단페스타가 오랫동안 반짝이는 기억으로 남아, 단국대학교가 여러분들의 진귀한
                        것들로 가득 찬 공간이 되길 바랍니다.
                     </p>
                     <p>
                        앞으로도 학우분들 모두가 문화를 향유하며 단국대학교에 애정을 느낄 수 있도록
                        노력하겠습니다.
                     </p>
                     <p>단국의 마음을 두드림, 함께 DO DREAM 감사합니다.</p>
                  </section>
               </Box>
            </SinglePageLayout>
         </ContentSection>
      </React.Fragment>
   );
}
