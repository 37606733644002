import React from 'react';

interface TableProps extends React.TableHTMLAttributes<HTMLTableElement> {
   tdWidth?: string;
}

export default function Table({ tdWidth, className, children }: TableProps) {
   return (
      <table
         className={`w-full text-center border-[1px] border-solid border-black [&>tbody>tr]:border-black [&>tbody>tr]:border-[1px] [&>tbody>tr]:border-solid [&>tbody>tr>td]:border-black [&>tbody>tr>td]:border-[1px] [&>tbody>tr>td]:border-solid ${className}`}
      >
         <tbody className={`[&>tr>td]:w-${tdWidth ?? 'auto'}`}>{children}</tbody>
      </table>
   );
}
