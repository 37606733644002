import { Gnb, GnbGoBack } from '@components/common/gnb';
import { ContentSection } from '@components/layouts';
import Term from '@components/signup/term';
import React from 'react';

import Table from '@/components/ui/table';

export default function SignupTerms() {
   return (
      <React.Fragment>
         <Gnb>
            <GnbGoBack />
         </Gnb>
         <ContentSection className='mt-[30px] mb-5'>
            <div className='px-10 pt-[60px]'>
               <h1 className='text-2xl font-extrabold mb-[14px]'>Sign up</h1>
               <h2 className='text-base font-extrabold mb-6'>단국대학교 총학생회 회원가입</h2>
               <h3 className="text-sm before:content-['●'] flex items-center gap-1">이용약관동의</h3>
               <Term termData={termData} />
            </div>
         </ContentSection>
      </React.Fragment>
   );
}

const termData = [
   {
      id: 'agreeCheck01',
      mainText: '[필수]',
      subText: '개인 정보 수집, 이용 동의',
      desc: (
         <>
            <p>
               ㈜무진정보기술 유메이트에서 서비스 회원가입 및 본인 인증을 위하여 아래와 같이 개인정보를 수집,
               이용합니다.
            </p>
            <Table tdWidth='1/3'>
               <tr>
                  <td>수집목적</td>
                  <td>수집항목</td>
                  <td>수집근거</td>
               </tr>
               <tr>
                  <td>회원 식별 및 회원제 서비스 제공</td>
                  <td>아이디, 비밀번호, 닉네임</td>
                  <td rowSpan={2}>개인정보 보호법 제15조 제1항</td>
               </tr>
               <tr>
                  <td>본인 인증</td>
                  <td>전화번호 </td>
               </tr>
            </Table>
            <p>
               귀하는 ㈜무진정보기술 유메이트 서비스 이용에 필요한 최소한의 개인정보 수집 및 이용에 동의하지
               않을 수 있으나, 동의를 거부 할 경우 회원제 서비스 이용이 불가합니다.
            </p>
         </>
      ),
   },
   {
      id: 'agreeCheck02',
      mainText: '[필수]',
      subText: '단국대학교 홈페이지 내 개인정보 수집, 이용 동의',
      desc: (
         <>
            <p>
               ㈜무진정보기술 유메이트는 단국대학교 학생임을 인증하기 위하여 아래와 같이 단국대학교 홈페이지
               내 개인정보를 수집, 이용합니다.
            </p>
            <Table tdWidth='1/3'>
               <tr>
                  <td>수집목적</td>
                  <td>수집항목</td>
                  <td>수집근거</td>
               </tr>
               <tr>
                  <td>단국대학교 학생임을 인증</td>
                  <td>단국대학교 홈페이지 아이디 및 비밀번호</td>
                  <td rowSpan={2}>개인정보 보호법 제15조 제1항</td>
               </tr>
               <tr>
                  <td>단국대학교 학생 정보 수집</td>
                  <td>이름, 단국대학교 학번, 소속 단과대학, 소속 학과, 재학 여부, 나이, 성별</td>
               </tr>
            </Table>
            <p>
               귀하는 ㈜무진정보기술 유메이트 서비스 이용에 필요한 최소한의 개인정보 수집 및 이용에 동의하지
               않을 수 있으나, 동의를 거부 할 경우 회원제 서비스 이용이 불가합니다. 단국대학교 홈페이지 아이디
               및 비밀번호는 연동 즉시 폐기됩니다.
            </p>
         </>
      ),
   },
   {
      id: 'agreeCheck03',
      mainText: '[필수]',
      subText: '개인정보 이용 동의',
      desc: (
         <>
            <p>
               ㈜무진정보기술 유메이트는 게시판 커뮤니티를 이용하기 위하여 아래와 같이 단국대학교 홈페이지 내
               개인정보를 이용합니다.
            </p>
            <Table tdWidth='1/3'>
               <tr>
                  <td>이용목적</td>
                  <td>이용항목</td>
                  <td>이용근거</td>
               </tr>
               <tr>
                  <td>청원 게시판 내 사용</td>
                  <td>소속 학과</td>
                  <td>개인정보 보호법 제15조 제1항</td>
               </tr>
            </Table>
            <p>
               귀하는 ㈜무진정보기술 유메이트 서비스 이용에 필요한 최소한의 개인정보 수집 및 이용에 동의하지
               않을 수 있으나, 동의를 거부 할 경우 회원제 서비스 이용이 불가합니다. 단국대학교 홈페이지 아이디
               및 비밀번호는 연동 즉시 폐기됩니다.
            </p>
         </>
      ),
   },
];
