import { Button } from '@components/ui/button';
import Checkbox from '@components/ui/checkbox';
import { Label } from '@components/ui/label';
import { ROUTES } from '@constants/route';
import React, { ReactNode } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import TermContent from './termContent';
export interface TermDataProps {
   id: string;
   mainText: string;
   subText: string;
   desc: ReactNode;
}
export default function Term({ termData }: { termData: TermDataProps[] }) {
   const allDisagree = Array(termData.length).fill(false);
   const allAgree = Array(termData.length).fill(true);
   const [agreeCheck, setAgreeCheck] = React.useState(allDisagree);
   const allChecked = agreeCheck.every(Boolean);

   const navigate = useNavigate();
   const [searchParams] = useSearchParams();

   const handleCheckboxChange = (index: number) => {
      const newAgreeCheck = [...agreeCheck];
      newAgreeCheck[index] = !newAgreeCheck[index];
      setAgreeCheck(newAgreeCheck);
   };
   const handleAllCheckboxChange = () => {
      const newAgreeCheck = allChecked ? allDisagree : allAgree;
      setAgreeCheck(newAgreeCheck);
   };
   const handleAgreeAll = () => {
      if (allChecked) {
         navigate(`${ROUTES.SIGNUP.ROOT}?${searchParams.toString()}`);
      } else {
         alert('약관에 동의해야 회원가입이 가능합니다.');
      }
   };

   return (
      <React.Fragment>
         <div className='flex flex-col gap-6'>
            <div className='ml-auto'>
               <Checkbox
                  id='allAgree'
                  direction='right'
                  checked={allChecked}
                  onCheckedChange={handleAllCheckboxChange}
               >
                  <Label htmlFor='allAgree' className='text-xs font-semibold'>
                     전체동의하기
                  </Label>
               </Checkbox>
            </div>
            {termData.map((data, index) => (
               <TermContent
                  key={index}
                  index={index}
                  handleCheckboxChange={(index: number) => handleCheckboxChange(index)}
                  termData={data}
                  agreeCheck={agreeCheck}
               />
            ))}
            <Button
               size='md'
               variant='default'
               className='rounded-[20px]'
               onClick={handleAgreeAll}
               disabled={!allChecked}
            >
               동의 완료
            </Button>
         </div>
      </React.Fragment>
   );
}
