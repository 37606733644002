import { Button } from '@components/ui/button';
import { Input } from '@components/ui/input';
import { API_PATH, CONSTANTS } from '@constants/api';
import { useAlert } from '@hooks/useAlert';
import { useApi } from '@hooks/useApi';
import { useEnrollmentStore } from '@stores/enrollment-store';
import { checkInputRegex } from '@utils/checkInputRegex';
import React, { useState } from 'react';
import { GoDotFill } from 'react-icons/go';

import { Gnb, GnbGoBack } from '@/components/common/gnb';
import { ContentSection } from '@/components/layouts';
import TermContent from '@/components/signup/termContent';
import Table from '@/components/ui/table';

export default function MyPageUpdate() {
   const [loginInfo, setLoginInfo] = useState({ dkuStudentId: '', dkuPassword: '' });
   const { post, patch } = useApi();
   const { alert } = useAlert();
   const { setEnrollment } = useEnrollmentStore();
   const [agreeCheck, setAgreeCheck] = useState([false]);

   const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (loginInfo.dkuStudentId.length > 0 && loginInfo.dkuPassword.length > 0) {
         try {
            await patch(`${CONSTANTS.SERVER_URL}${API_PATH.USER.DKU.INDEX}`, loginInfo, {
               authenticate: true,
            });
            await post(`${CONSTANTS.SERVER_URL}${API_PATH.USER.DKU.REFRESH}`, loginInfo, {
               authenticate: true,
            });
            alert('인증되었습니다.');
            window.location.href = '/';
            setEnrollment(true);
         } catch (error) {
            alert(error);
         }
      } else {
         alert('모두 입력해주세요.');
      }
   };

   return (
      <>
         <Gnb>
            <GnbGoBack
               onClick={() => {
                  window.location.href = '/';
               }}
            />
         </Gnb>
         <ContentSection>
            <form
               className='border-none mt-10 flex flex-col gap-3 px-6'
               onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                  handleSubmit(e);
               }}
            >
               <p className='text-2xl font-bold'>회원 정보 업데이트</p>
               <p className='flex text-sm items-center'>
                  <GoDotFill />
                  학생 인증
               </p>
               {!agreeCheck[0] ? (
                  <TermContent
                     height='300px'
                     termData={termData}
                     index={0}
                     agreeCheck={agreeCheck}
                     handleCheckboxChange={() => {
                        setAgreeCheck([!agreeCheck[0]]);
                     }}
                  />
               ) : (
                  <>
                     <div>
                        <Input
                           onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              setLoginInfo((prev) => ({
                                 ...prev,
                                 dkuStudentId: checkInputRegex(e.target.value, 'number') ?? '',
                              }));
                           }}
                           maxLength={8}
                           placeholder='학번 입력'
                           className='w-full rounded-lg mb-3 py-3 px-4'
                           value={loginInfo.dkuStudentId}
                        />
                        <Input
                           onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              setLoginInfo((prev) => ({ ...prev, dkuPassword: e.target.value }));
                           }}
                           placeholder='비밀번호 입력'
                           type='password'
                           className='w-full rounded-lg py-3 px-4'
                           value={loginInfo.dkuPassword}
                        />
                     </div>
                     <p className='flex text-sm gap-1 text-neutral-400'>
                        <GoDotFill size={30} />
                        단국대학교 웹정보 로그인 시 사용 되는 ID, PW를 통해 학생인증이 진행됩니다. (입력한
                        정보는 인증 후 즉시 폐기됩니다)
                     </p>
                     <Button type='submit' size='md' className='w-full'>
                        인증
                     </Button>
                  </>
               )}
            </form>
         </ContentSection>
      </>
   );
}

const termData = {
   id: 'agreeCheck05',
   mainText: '[필수]',
   subText: '재학 인증 관련 개인정보 이용 동의',
   desc: (
      <>
         <p>
            ㈜무진정보기술 유메이트는 단국대학교 학생임을 인증하기 위하여 아래와 같이 단국대학교 홈페이지 내
            개인정보를 수집, 이용합니다.
         </p>
         <Table tdWidth='1/3'>
            <tr>
               <td>수집목적</td>
               <td>수집항목</td>
               <td>수집근거</td>
            </tr>
            <tr>
               <td>단국대학교 학생임을 인증</td>
               <td>단국대학교 홈페이지 아이디 및 비밀번호</td>
               <td rowSpan={2}>개인정보 보호법 제15조 제1항</td>
            </tr>
            <tr>
               <td>학번, 이름, 학과, 재학 여부 인증</td>
               <td>재학여부</td>
            </tr>
         </Table>
         <p>
            귀하는 ㈜무진정보기술 유메이트 서비스 이용에 필요한 최소한의 개인정보 수집 및 이용에 동의하지 않을
            수 있으나, 동의를 거부 할 경우 회원제 서비스 이용이 불가합니다. 단국대학교 홈페이지 아이디 및
            비밀번호는 연동 즉시 폐기됩니다.
         </p>
      </>
   ),
};
