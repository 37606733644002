import Box from '@components/ui/box';
import { Button } from '@components/ui/button';
import { HEADING_TEXT } from '@constants/heading';
import { ROUTES } from '@constants/route';
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Gnb, GnbGoBack } from '@/components/common/gnb';
import { GnhSubtitle, GnhTitle } from '@/components/common/gnh';
import { ContentSection, HeaderSection } from '@/components/layouts';
import TermContent from '@/components/signup/termContent';
import Table from '@/components/ui/table';

export default function ResetIdPw() {
   const navigate = useNavigate();
   const [searchParams] = useSearchParams();
   const [agreeCheck, setAgreeCheck] = useState([false]);

   const handleFindId = () => {
      navigate(`${ROUTES.RESET.ID}?${searchParams.toString()}`);
   };

   const handleVerifyPw = () => {
      navigate(`${ROUTES.RESET.PW_VERIFY}?${searchParams.toString()}`);
   };

   return (
      <React.Fragment>
         <Gnb>
            <GnbGoBack />
         </Gnb>
         <HeaderSection className='text-center pt-4 pb-[55px]'>
            <GnhTitle className='mb-[19px]'>{HEADING_TEXT.LOGIN.HEAD}</GnhTitle>
            <GnhSubtitle>{HEADING_TEXT.RESET_ID_PW.SUBHEAD}</GnhSubtitle>
         </HeaderSection>
         <ContentSection showNav={true} className='items-center'>
            {!agreeCheck[0] ? (
               <div className='p-4'>
                  <TermContent
                     height='300px'
                     termData={termData}
                     index={0}
                     agreeCheck={agreeCheck}
                     handleCheckboxChange={() => {
                        setAgreeCheck([!agreeCheck[0]]);
                     }}
                  />
               </div>
            ) : (
               <>
                  <section className='mb-5'>
                     <Box className='mb-4' type='shadow'>
                        <h3 className='text-xl font-extrabold mb-[10px]'>ID 찾기</h3>
                        <p className='whitespace-pre-line text-[13px]'>
                           {
                              '잃어버린 ID에 대해서 휴대전화 번호를 입력하면,\n 문자를 통해서 ID를 제공 받습니다.'
                           }
                        </p>
                     </Box>
                     <Button size='xl' variant='default' className='rounded-[30px]' onClick={handleFindId}>
                        ID 찾기
                     </Button>
                  </section>
                  <Box className='mb-4' type='shadow'>
                     <h3 className='text-xl font-extrabold mb-[10px]'>PW 재설정</h3>
                     <p className='text-[13px] whitespace-pre-line'>
                        {
                           '잃어버린 PW에 대해서 휴대전화번호를 입력하면,\n 인증번호 제공을 통해 새로운 비밀번호를 설정합니다.'
                        }
                     </p>
                  </Box>
                  <Button size='xl' variant='default' className='rounded-[30px]' onClick={handleVerifyPw}>
                     PW 재설정
                  </Button>
               </>
            )}
         </ContentSection>
      </React.Fragment>
   );
}

const termData = {
   id: 'agreeCheck06',
   mainText: '[필수]',
   subText: 'ID/PW 찾기 관련 개인정보 이용 동의',
   desc: (
      <>
         <p>
            ㈜무진정보기술 유메이트는 단국대학교 학생임을 인증하기 위하여 아래와 같이 단국대학교 홈페이지 내
            개인정보를 수집, 이용합니다.
         </p>
         <Table tdWidth='1/3'>
            <tr>
               <td>수집목적</td>
               <td>수집항목</td>
               <td>수집근거</td>
            </tr>
            <tr>
               <td>본인 인증</td>
               <td>전화 번호</td>
               <td>개인정보 보호법 제15조 제1항</td>
            </tr>
         </Table>
         <p>
            귀하는 ㈜무진정보기술 유메이트 서비스 이용에 필요한 최소한의 개인정보 수집 및 이용에 동의하지 않을
            수 있으나, 동의를 거부 할 경우 회원제 서비스 이용이 불가합니다. 단국대학교 홈페이지 아이디 및
            비밀번호는 연동 즉시 폐기됩니다.
         </p>
      </>
   ),
};
