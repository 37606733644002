export type TokenType = {
   accessToken: string;
   refreshToken: string;
};

export const getAccessToken = () => {
   return localStorage.getItem('damda-atk');
};

export const getRefreshToken = () => {
   return localStorage.getItem('damda-rtk');
};

export const setToken = ({ accessToken, refreshToken }: TokenType) => {
   localStorage.setItem('damda-atk', accessToken);
   localStorage.setItem('damda-rtk', refreshToken);
   document.cookie = `access-token=${accessToken}; max-age=86400`;
   document.cookie = `refresh-token=${refreshToken}; max-age=86400`;
};

export const removeToken = () => {
   localStorage.removeItem('damda-atk');
   localStorage.removeItem('damda-rtk');
   document.cookie = 'access-token=; max-age=0';
   document.cookie = 'refresh-token=; max-age=0';
};

export const isLoggedIn = getAccessToken() ? true : false;
